import React from "react"
import styled from "styled-components"
import badge from "../assets/pen-badge.png"

const ScreenSaverStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 999999999;
  #screen-saver-inner {
    position: absolute;
    max-width: 50vw;
    img {
      width: 100%;
    }
  }
`

export default function ScreenSaver() {
  const [idle, setIdle] = React.useState(false)

  function screenSaverInit() {
    const object = document.getElementById("screen-saver-inner")
    const container = document.getElementById("screen-saver-container")

    const width = container.getBoundingClientRect().width
    const height = container.getBoundingClientRect().height

    let positionX = width / 2
    let positionY = height / 2
    let movementX = 5
    let movementY = 5

    const animation = () => {
      positionY += movementY
      positionX += movementX

      if (positionY < 0 || positionY >= height - object.offsetHeight) {
        movementY = -movementY
      }
      if (positionX <= 0 || positionX >= width - object.clientWidth) {
        movementX = -movementX
      }

      object.style.top = positionY + "px"
      object.style.left = positionX + "px"

      requestAnimationFrame(animation)
    }
    requestAnimationFrame(animation)
  }

  React.useEffect(() => {
    const events = ["keydown", "mousemove", "touchstart"]
    let timeout

    events.forEach((event) => {
      document.addEventListener(event, () => {
        setIdle(false)
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          setIdle(true)
          screenSaverInit()
        }, 70000)
      })
    })
  })

  return (
    <>
      <ScreenSaverStyles
        id="screen-saver-container"
        style={{ display: idle ? "block" : "none" }}
      >
        <div id="screen-saver-inner">
          <img src={badge} alt="screensaver" />
        </div>
      </ScreenSaverStyles>
    </>
  )
}
