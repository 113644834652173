import React from "react"
import { useGameDispatchContext } from "../../utils/gameReducer"
import badge from "../../assets/pen-badge.png"

export default function HomeScreenOne({ setPagination }) {
  const dispatch = useGameDispatchContext()

  function handleButtonClick() {
    setPagination(1)
    if (
      localStorage.getItem("music") === null ||
      localStorage.getItem("music") === "true"
    ) {
      dispatch({ type: "UPDATE_AUDIO", audio: true })
      document.getElementById("music").play()
    }
  }

  return (
    <>
      <img
        style={{ display: "block", width: "80%", objectFit: "cover" }}
        src={badge}
        alt="Penhaligon's"
      />
      <p>
        The nasal confectioner gets terribly cheeky this time of year. He’s
        hidden some sweets around the factory and we need your help locating
        them.
      </p>
      <button className="button" onClick={handleButtonClick}>
        Get searching
      </button>
    </>
  )
}
