import React from "react"
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import Home from "./views/Home"
import Play from "./views/Play"
import Layout from "./components/Layout"
import ScreenSaver from "./components/ScreenSaver"

export default function App() {
  return (
    <>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/play">
              <Play />
            </Route>
            <Route>
              <Home />
            </Route>
          </Switch>
        </Layout>
      </Router>
      <ScreenSaver />
    </>
  )
}
