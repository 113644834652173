import React from "react"
import { Link } from "react-router-dom"

export default function PrizeScreen({
  title,
  image,
  text,
  button,
  lost,
  prize,
  playAgain,
  startNewGame,
}) {
  return (
    <>
      <h1> {title}</h1>
      <img
        src={image}
        alt="prize"
        style={{ width: "100%", objectFit: "cover" }}
      />
      <p>{text}</p>
      {prize !== "LOST" ? (
        <Link className="button" to="/">
          {playAgain}
        </Link>
      ) : (
        <button className="button" onClick={startNewGame}>
          {playAgain}
        </button>
      )}
    </>
  )
}
