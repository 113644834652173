import React from "react"
import { Redirect } from "react-router-dom"
import { useGameStateContext } from "../utils/gameReducer"
import Popup from "./Popup"
import PrizeScreen from "./PrizeScreen"
import lostImg from "../assets/pen-lost.png"
import prizeImg from "../assets/pen-generic-prize.png"

export default function Prize({ startNewGame }) {
  const [goHome, setGoHome] = React.useState(false)
  const { prize } = useGameStateContext()

  React.useEffect(() => {
    setTimeout(() => {
      setGoHome(true)
    }, 60000)
  })

  if (goHome) {
    return <Redirect to="/" />
  }

  return (
    <>
      <Popup>
        {prize === "LOST" ? (
          <PrizeScreen
            title="Oh blast!"
            image={lostImg}
            text="You need a bit of practice, old bean. You have not won a prize this time."
            prize={prize}
            playAgain="Have another try"
            startNewGame={startNewGame}
          />
        ) : prize === "BRONZE" ? (
          <PrizeScreen
            title="Game over"
            image={prizeImg}
            text="Speak to a Penhaligon’s Fragrance Consultant to claim your Bronze Prize."
            prize={prize}
            playAgain="Play Again"
            startNewGame={startNewGame}
          />
        ) : prize === "SILVER" ? (
          <PrizeScreen
            title="Game over"
            image={prizeImg}
            text="Speak to a Penhaligon’s Fragrance Consultant to claim your Silver Prize."
            prize={prize}
            playAgain="Play Again"
            startNewGame={startNewGame}
          />
        ) : (
          <PrizeScreen
            title="Game over"
            image={prizeImg}
            text="Speak to a Penhaligon’s Fragrance Consultant to claim your Gold Prize."
            prize={prize}
            playAgain="Play Again"
            startNewGame={startNewGame}
          />
        )}
      </Popup>
    </>
  )
}
