import React from "react"
import music from "../assets/music.mp3"

export default function Layout({ children }) {
  return (
    <>
      <main>{children}</main>
      <audio id="music" src={music} loop />
    </>
  )
}
